<template>
  <el-main>
    <div class="total">
      <div class="item">
        <p>{{ amount_sum }}</p>
        <p>会员/用户总收入</p>
      </div>
      <div class="item">
        <p>{{ cash_sum }}</p>
        <p>现有余额</p>
      </div>
      <div class="item">
        <p>{{ apply_sum }}</p>
        <p>已有提现金额</p>
      </div>
      <div class="item">
        <p>{{ frozen_sum }}</p>
        <p>冻结金额</p>
      </div>
    </div>
    <el-form class="el-form-search" label-width="120px">
      <el-form-item label="用户昵称：">
        <el-input v-model="keywords_type.nickname" size="small" @input="getKeywords($event, 1)"></el-input>
      </el-form-item>
      <el-form-item label="师傅/店铺名称：">
        <el-input v-model="keywords_type.member" size="small" @input="getKeywords($event, 2)"></el-input>
      </el-form-item>
      <el-form-item label="关联单号：">
        <el-input v-model="keywords_type.order_sn" size="small" placeholder="搜索入账订单号" @input="getKeywords($event, 3)"></el-input>
      </el-form-item>
      <el-form-item label="来源类型：">
        <el-select v-model="searchForm.service_type" size="small" placeholder="请选择">
          <el-option label="全部" value=""></el-option>
          <el-option label="商品" :value="1"></el-option>
          <el-option label="服务" :value="2"></el-option>
          <el-option label="分销" :value="3"></el-option>
          <el-option label="提现" :value="4"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="收益/支出：">
        <el-select v-model="searchForm.status" size="small" placeholder="请选择">
          <el-option label="全部" value=""></el-option>
          <el-option label="收益" :value="1"></el-option>
          <el-option label="支出" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="资金状态：">
        <el-select v-model="searchForm.type" size="small" placeholder="请选择">
          <el-option label="全部" value=""></el-option>
          <el-option label="待入账" :value="1"></el-option>
          <el-option label="已入账" :value="2"></el-option>
          <el-option label="已失效" :value="3"></el-option>
          <el-option label="冻结中" :value="4"></el-option>
          <el-option label="提现中" :value="5"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="产出时间：">
        <el-date-picker
          v-model="searchForm.create_time"
          size="small"
          type="daterange"
          value-format="timestamp"
          range-separator="~"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label=" " label-width="30px">
        <el-button type="primary" size="small" @click="search">搜索</el-button>
        <el-button type="text" size="small" @click="cancelSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="id" label="ID" width="90" align="center"></el-table-column>
      <el-table-column prop="name" label="用户信息" width="200" align="center">
        <template v-slot="{ row }">
          <userInfo
            :name="row.status == 1 ? row.order_info.extend_user.nickname : row.mechanic_info.name"
            :avatar="row.mechanic_info ? row.mechanic_info.avatar : 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/orderDefault.png'"
          ></userInfo>
        </template>
      </el-table-column>
      <el-table-column prop="mechanic_info.name" label="师傅/店铺名称" width="200" align="center"></el-table-column>
      <el-table-column prop="service_type" label="来源类型" align="center">
        <template v-slot="{ row }">{{ row.service_type == 1 ? '商品' : row.service_type == 2 ? '服务' : row.service_type == 3 ? '分销' : '提现' }}</template>
      </el-table-column>
      <el-table-column prop="status" label="收益/支出" align="center">
        <template v-slot="{ row }">{{ row.status == 1 ? '收益' : '支出' }}</template>
      </el-table-column>
      <el-table-column prop="type" label="资金状态" align="center">
        <template v-slot="{ row }">{{ row.type == 1 ? '待入账' : row.type == 2 ? '已入账' : row.type == 3 ? '已失效' : row.type == 4 ? '冻结中' : '提现中' }}</template>
      </el-table-column>
      <el-table-column prop="money" label="金额" align="center">
        <template v-slot="{ row }">
          <moneyInfo v-if="row.service_type == 4" :accountprice="row.order_info.accountprice" :applyprice="row.order_info.applyprice"></moneyInfo>
          <span :style="{ color: row.type == 3 ? '#DCDFE6' : row.status == 1 ? '#EA4335' : '#34A853' }">{{ row.status == 1 ? '+' + row.money : '-' + row.money }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="order_info.applysn" label="关联单号" align="center">
        <template v-slot="{ row }">
          {{ row.service_type == 4 ? row.order_info.applysn : row.order_info.pay_sn }}
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="产生时间" align="center">
        <template v-slot="{ row }">{{ getDateformat(row.create_time) }}</template>
      </el-table-column>
    </el-table>
    <Paging :total="total" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData"></Paging>
  </el-main>
</template>
<script>
import _ from 'lodash';
import { getDateformat } from '@/util/getDate';
import Paging from '@/components/paging';
import userInfo from '@/components/userInfo';
import moneyInfo from './components/MoneyInfo';
export default {
  components: {
    Paging,
    userInfo,
    moneyInfo,
  },
  data() {
    return {
      keywords_type: {
        member: '',
        order_sn: '',
        nickname: '',
      },
      searchForm: {
        page: 1,
        rows: 10,
        keyworks_type: '',
        keyworks: '',
        status: '',
        create_time: '',
        service_type: '',
        type: '',
      },
      amount_sum: '0.00',
      apply_sum: '0.00',
      cash_sum: '0.00',
      frozen_sum: '0.00',
      list: [],
      total: 0,
    };
  },
  created() {
    let query = this.$route.query;
    if (query.keyworks) {
      this.keywords_type.member = query.keyworks;
      this.searchForm.keyworks = query.keyworks;
      this.searchForm.keyworks_type = 'member';
      this.searchForm.service_type = Number(query.service_type) || '';
    }
    this.getList();
  },
  methods: {
    search() {
      this.searchForm.page = 1;
      this.getList();
    },
    cancelSearch() {
      this.searchForm = {
        page: 1,
        rows: 10,
        keyworks_type: '',
        keyworks: '',
        status: '',
        create_time: '',
        service_type: '',
        type: '',
      };
      this.resetKeywordType();
      this.getList();
    },
    getDateformat(val) {
      return getDateformat(val);
    },
    updateData(val, status) {
      if (status == 0) {
        this.searchForm.rows = val;
        this.getList();
      } else {
        this.searchForm.page = val;
        this.getList();
      }
    },
    resetKeywordType(key) {
      for (let i in this.keywords_type) {
        if (i != key) this.keywords_type[i] = '';
      }
    },
    getKeywords(val, type) {
      let searchForm = this.searchForm;
      switch (type) {
        case 1:
          this.resetKeywordType('nickname');
          searchForm.keyworks_type = 'nickname';
          break;
        case 2:
          this.resetKeywordType('member');
          searchForm.keyworks_type = 'member';
          break;
        case 3:
          this.resetKeywordType('order_sn');
          searchForm.keyworks_type = 'order_sn';
          break;
      }
      searchForm.keyworks = val;
    },
    getList() {
      let searchForm = _.cloneDeep(this.searchForm);
      let data = {
        page: searchForm.page,
        rows: searchForm.rows,
      };
      if (searchForm.keyworks) {
        data.keyworks = searchForm.keyworks;
        data.keyworks_type = searchForm.keyworks_type;
      }
      if (searchForm.status) {
        data.status = searchForm.status;
      }
      if (searchForm.service_type) {
        data.service_type = searchForm.service_type;
      }
      if (searchForm.type) {
        data.type = searchForm.type;
      }
      if (searchForm.create_time) {
        for (let i in searchForm.create_time) {
          searchForm.create_time[i] = searchForm.create_time[i] / 1000;
        }
        data.create_time = searchForm.create_time;
      }
      this.$axios.post(this.$api.samecity.cashLogList, data).then(res => {
        if (res.code == 0) {
          this.list = res.result.list;
          this.amount_sum = res.result.amount_sum;
          this.apply_sum = res.result.apply_sum;
          this.cash_sum = res.result.cash_sum;
          this.frozen_sum = res.result.frozen_sum;
          this.total = res.result.total;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
  .total {
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    .item {
      width: 372px;
      height: 120px;
      color: #fff;
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding-left: 20px;
      p:nth-child(1) {
        font-size: 36px;
        font-weight: bold;
        margin-bottom: 10px;
      }
      p:nth-child(2) {
        font-size: 14px;
      }
      &:nth-child(1) {
        background: url('~@/assets/image/assets_back1.png') no-repeat;
        background-size: 100% 100%;
      }
      &:nth-child(2) {
        background: url('~@/assets/image/assets_back2.png') no-repeat;
        background-size: 100% 100%;
      }
      &:nth-child(3) {
        background: url('~@/assets/image/assets_back3.png') no-repeat;
        background-size: 100% 100%;
      }
      &:nth-child(4) {
        background: url('~@/assets/image/assets_back4.png') no-repeat;
        background-size: 100% 100%;
      }
    }
  }
}
</style>